import React from 'react'
import {
  Button,
  Div,
  Flex,
  Sheet,
  Spacer,
  Text,
} from '@sendoutcards/quantum-design-ui'

import {
  useAccount,
  useActions,
  useCallback,
  useMutations,
  useQueryParams,
  useState,
  useVertical,
} from 'src/hooks'

import { PersonalSiteOptions, SponsorCard } from 'src/dashboard/components'
import StreamLinksCard from 'src/dashboard/components/StreamLinksCard'
import { SENDOGO_RP_STRIPE_ID } from 'src/marketing/plans/constants'
import VerticalTabs from 'src/dashboard/components/VerticalTabs'
import SharingInformationCard from 'src/dashboard/components/SharingInformationCard'
import { FlexRow } from 'src/styled'
import ProgramOptIn, {
  ActionKeys,
} from 'src/onboarding/components/ProgramOptIn'
import { Alert } from 'src/chrome'
import {
  OverridableUserLabel,
  StorefrontFragment,
  StripeSubscriptionFragment,
  UserLabelOverrideAction,
  UserType,
} from 'src/graphql/generated/graphql'
import {
  useCreateAffiliate,
  useUpdateAccount,
  useUpdateUserLabelOverrides,
} from 'src/react_query'

/**
 *
 * TODO:
 * [] - Pull in All Account Form components and logic
 *  1. Personal Info Form
 *  2. My Address Form
 *  3. Communication Settings
 *  4. Sharing Settings Component
 *  5. Stream Links?
 *
 * [] - Wire up route
 *
 */
export const ShareSettingsView = () => {
  const actions = useActions()
  const mutations = useMutations()
  const account = useAccount()
  const queryParams = useQueryParams()
  const vertical = useVertical()
  const updateAccountMutation = useUpdateAccount()
  const createAffiliate = useCreateAffiliate()
  const updateUserLabelOverrides = useUpdateUserLabelOverrides()

  const [storefront, setStorefront] = useState(account.storefront)
  const [isGSAOptInOpen, setIsGSAOptInOpen] = useState(false)
  const [isAlertOpen, setIsAlertOpen] = useState(false)
  const [affiliateOptInResponse, setAffiliateOptInResponse] = useState('')
  const [custOptInResponse, setCustOptInResponse] = useState('')

  const handleStorefrontUpdate = useCallback(
    async (storefront: StorefrontFragment) => {
      const { biography, slug } = storefront

      try {
        const result = await updateAccountMutation.mutateAsync({
          account: {
            storefront: {
              biography: biography,
              slug: slug,
            },
          },
        })

        actions.updatedAccount(result.updateAccount.account)
        setStorefront(result.updateAccount.account.storefront)
      } catch (error) {
        console.error(error)
      }
    },
    [actions, updateAccountMutation],
  )

  const handleSaveMugshot = useCallback(
    async (file: File) => {
      if (!file) return
      const data = await mutations.updateStorefrontMugshot({
        image_file: file,
        tags: ['prompt-editor'],
      })
      setStorefront(() => ({
        ...data.updateStorefrontMugshot.storefront,
      }))
      actions.updatedAccount({
        ...account,
        storefront: data.updateStorefrontMugshot.storefront,
      })
    },
    [account, actions, mutations],
  )
  const handleGSAOptIn = () => {
    setIsGSAOptInOpen(!isGSAOptInOpen)
  }
  const toggleAlert = useCallback(() => {
    setIsAlertOpen(prevShowAlert => !prevShowAlert)
  }, [])

  const onStepsComplete = () => {
    // Affiliate Options
    if (affiliateOptInResponse === ActionKeys.optIn) {
      createAffiliate.mutateAsync({
        account: { username: account.username },
        govId1: '',
        verticalId: vertical.graphqlVerticalID,
        queryParams,
      })
    }

    if (affiliateOptInResponse === ActionKeys.optOut) {
      updateUserLabelOverrides.mutateAsync({
        overrides: [
          {
            label: OverridableUserLabel.NeedsGsAffiliateOptIn,
            action: UserLabelOverrideAction.Exclude,
          },
        ],
      })
    }
    // Customer options
    if (custOptInResponse === ActionKeys.optIn) {
      createAffiliate.mutateAsync({
        account: { username: account.username },
        govId1: '',
        affiliate: false,
        verticalId: vertical.graphqlVerticalID,
        queryParams,
      })
    }

    if (custOptInResponse === ActionKeys.optOut) {
      updateUserLabelOverrides.mutateAsync({
        overrides: [
          {
            label: OverridableUserLabel.NeedsCustomerReferralOptIn,
            action: UserLabelOverrideAction.Exclude,
          },
        ],
      })
    }
  }

  const isGSAffiliate = account.type === UserType.Gsa
  const isCustomer = account.type === UserType.C
  const isDistributor = account.type === UserType.D
  const isSRP =
    account &&
    account.subscriptions.filter(
      (sub: StripeSubscriptionFragment) => sub.sku === SENDOGO_RP_STRIPE_ID,
    ).length >= 1
  return (
    <Flex flexDirection={'column'} width="100%">
      <SharingInformationCard>
        {storefront && (account.isAffiliate || isGSAffiliate) && (
          <>
            <Div
              inset={{ bottom: 'x1' }}
              outset={{ bottom: 'x1' }}
              maxWidth="400px"
            >
              <Text
                type="body"
                content="My Affiliate Information Settings"
                weight="bold"
                color="primaryHeading"
              />
              <Text
                type="caption"
                content="Edit the information shown when you share your coded links with others."
                color="primaryBody"
              />
            </Div>
            <PersonalSiteOptions
              account={account}
              storefront={storefront}
              onSave={handleStorefrontUpdate}
              onSaveMugshot={handleSaveMugshot}
            />
          </>
        )}
        <Div
          width="100%"
          style={{ borderBottom: '2px solid #f7f7f7' }}
          outset={{ vertical: 'x3' }}
        />
        {account.sponsor && !account.isOrphan && (
          <Flex flexDirection="column">
            <Div
              inset={{ bottom: 'x1' }}
              outset={{ bottom: 'x1' }}
              maxWidth="400px"
            >
              <Text
                type="body"
                content="My Sponsor's Information"
                weight="bold"
                color="primaryHeading"
              />
              <Text
                type="caption"
                content="This is the person(s) that referred you to Promptings"
                color="primaryBody"
              />
            </Div>
            <SponsorCard sponsor={account.sponsor} />
          </Flex>
        )}
        <Div
          width="100%"
          style={{ borderBottom: '2px solid #f7f7f7' }}
          outset={{ vertical: 'x3' }}
        />
        <Div inset={{ bottom: 'x1' }} outset={{ bottom: 'x1' }} maxWidth="100%">
          <Text
            type="body"
            content="My Site Sharing Links"
            weight="bold"
            color="primaryHeading"
          />
          <Text
            type="caption"
            content="Select any of the following links to share with your prospects to gain credit for their purchases."
            color="primaryBody"
          />
        </Div>
        <FlexRow style={{ justifyContent: 'space-around' }}>
          {isCustomer && (
            <Button
              title="Join Customer Referral Program"
              onClick={handleGSAOptIn}
            />
          )}
          {isDistributor && (
            <Button
              title="Join Promptings as an Affiliate"
              onClick={handleGSAOptIn}
            />
          )}
        </FlexRow>
        <Spacer space="x2" />
        <div style={{ overflowX: 'auto' }}>
          <VerticalTabs toggleAlert={toggleAlert} />
        </div>
      </SharingInformationCard>
      <StreamLinksCard isAffiliate={account.isAffiliate} isSRP={isSRP} />
      {isGSAOptInOpen && (
        <Sheet isOpen={true}>
          {isDistributor ? (
            <ProgramOptIn
              message="greenerStillAffiliateOptInMessage"
              onComplete={() => console.log()}
              setResponse={setAffiliateOptInResponse}
            />
          ) : (
            <ProgramOptIn
              message="customerReferralOptInMessage"
              onComplete={() => console.log()}
              setResponse={setCustOptInResponse}
            />
          )}
          <FlexRow css={{ justifyContent: 'space-between', width: 'inherit' }}>
            <Button
              title={'Cancel'}
              type={'secondary'}
              size={'medium'}
              onClick={() => setIsGSAOptInOpen(false)}
            />
            <Button
              title={'Submit'}
              type={'primary'}
              size={'medium'}
              onClick={() => {
                onStepsComplete()
                setIsGSAOptInOpen(false)
              }}
            />
          </FlexRow>
        </Sheet>
      )}
      {isAlertOpen && (
        <Alert
          title=""
          isOpen={isAlertOpen}
          onClose={toggleAlert}
          type={'info'}
          timeout={3000}
          message={'Link copied to clipboard'}
        />
      )}
    </Flex>
  )
}
